import React from 'react';
import { MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBView } from 'mdbreact';
import './carrossel.css';

const ImageCarousel = ({ images }) => {
  return (
    <MDBCarousel activeItem={1} length={images.length} showControls={true} showIndicators={true} className="z-depth-1">
      <MDBCarouselInner>
        {images.map((image, index) => (
          <MDBCarouselItem itemId={index + 1} key={index}>
            <MDBView>
              <img className="d-block w-100" src={image.src} alt={image.alt} />
            </MDBView>
          </MDBCarouselItem>
        ))}
      </MDBCarouselInner>
    </MDBCarousel>
  );
};

export default ImageCarousel;

