import * as React from "react";
import { navigate } from "gatsby";
import {
	MDBCard,
	MDBRow,
	MDBCol,
	MDBCardBody,
	MDBCardImage,
	MDBCardText,
	MDBContainer,
	MDBBtn,
} from "mdbreact";
import Layout from "../Layouts/MasterLayout/MasterLayout";
import SEO from "../components/SEO/Seo";
import CarrosselPolos from "../components/CarrosselPolos/carrossel";

export default function PolosPage() {
	const conteudo = [
		{
			title: "Campo Bom",
			location:
				"Av. Independência, 700, Centro, Campo Bom, RS. Cidade Shopping: sala 130.",
			image:
				[
				{ src: 'https://www.feevale.br/Comum/midias/b6e29cd6-eb50-422b-bfce-b66b897a2ba4.jpg', alt: 'Image 1' },
				{ src: 'https://www.feevale.br/Comum/midias/87ee19b8-19b0-42eb-be67-92004da6f4c7.jpg', alt: 'Image 2' },
				{ src: 'https://www.feevale.br/Comum/midias/ea5a2606-a939-45f7-bb4f-cf67f468d2cc.jpg', alt: 'Image 3' },
				{ src: 'https://www.feevale.br/Comum/midias/a8967ef8-3e82-46dc-93c7-b1a77fd3ab0a.jpg', alt: 'Image 4' },
				{ src: 'https://www.feevale.br/Comum/midias/55f03b68-0b77-45c5-a70d-d7a76412ffdd.jpg', alt: 'Image 5' },
				{ src: 'https://www.feevale.br/Comum/midias/661671be-d297-48e6-b109-6b3f598d080a.jpg', alt: 'Image 6' },
				{ src: 'https://www.feevale.br/Comum/midias/c26c9d2c-d130-40b9-907b-80ad033e6772.jpg', alt: 'Image 7' },
				{ src: 'https://www.feevale.br/Comum/midias/9681f017-0e4f-476b-bc56-47d38dbc8d33.jpg', alt: 'Image 8' },
			  ],
			galleryUrl:
				"https://www.flickr.com/photos/fotosfeevale/albums/72157704762244402",
			phone: "(51) 3586.8800, ramal 6939",
			gotoUrl:
				"https://www.google.com/maps?ll=-29.68169,-51.0579&z=15&t=m&hl=pt-BR&gl=BR&mapclient=embed&q=Av.+Independ%C3%AAncia,+700+-+Centro+Hist%C3%B3rico+Campo+Bom+-+RS+93700-000",
			capacidade:
				"Capacidade sala Digital: 30 lugares.<br/>Capacidade sala Tutoria: 8 lugares.",
		},
		{
			title: "Esteio",
			location: "Rua Engenheiro Hener de Souza Nunes, 102, Centro, Esteio, RS.",
			image:
				[
				{ src: 'https://www.feevale.br/Comum/midias/447d1357-799c-4aec-b5aa-31a3d7b6c1d3.png', alt: 'Image 1' },
				{ src: 'https://www.feevale.br/Comum/midias/759c60a4-4dcb-48f2-95cd-f82ce1377495.png', alt: 'Image 2' },
				{ src: 'https://www.feevale.br/Comum/midias/06e0e0e9-67b4-4cf3-bf8f-5b17d2a8e9f0.png', alt: 'Image 3' },
				{ src: 'https://www.feevale.br/Comum/midias/38aae3d4-8449-41d8-8c25-af099e3a8002.jpg', alt: 'Image 4' },
				{ src: 'https://www.feevale.br/Comum/midias/4c6e5bee-86c9-41aa-a6a0-2900ced0d966.png', alt: 'Image 5' },
				{ src: 'https://www.feevale.br/Comum/midias/d03a42d0-8d03-4f0f-bc78-88580ab354fa.png', alt: 'Image 6' },
			  ],
			phone: "(51) 3586.8800, ramal 6934",
			galleryUrl:
				"https://www.flickr.com/photos/fotosfeevale/albums/72157707862652065",
			gotoUrl:
				"https://www.google.com/maps?ll=-29.853058,-51.178413&z=15&t=m&hl=pt-BR&gl=BR&mapclient=embed&q=R.+Eng.+Hener+de+Souza+Nunes,+102+-+Centro+Esteio+-+RS+93260-120",
			capacidade:
				"Capacidade sala Digital: 18 lugares.<br/>Capacidade sala Tutoria: 8 lugares.",
		},
		{
			title: "Gramado",
			location: "Rua São Pedro, 532, Centro, Gramado, RS.",
			image:
				[
				{ src: 'https://www.feevale.br/Comum/midias/2ef2de61-3afd-42f0-a4a3-f3d60cf1e178.png', alt: 'Image 1' },
				{ src: 'https://www.feevale.br/Comum/midias/6560324f-1aa9-494a-af09-882c0a7b16c5.jpg', alt: 'Image 2' },
				{ src: 'https://www.feevale.br/Comum/midias/f22440f4-3bb9-4dfb-94f7-1e8fd4402e5d.jpg', alt: 'Image 3' },
				{ src: 'https://www.feevale.br/Comum/midias/2a9d33e8-64c4-440a-b16e-1c18df8a18a7.jpg', alt: 'Image 4' },
				{ src: 'https://www.feevale.br/Comum/midias/e3b7b67f-bc07-4fce-97d4-085439ce44d8.png', alt: 'Image 5' },
			  ],
			phone: "(51) 3586.8800, ramal 6941",
			galleryUrl:
				"https://www.flickr.com/photos/fotosfeevale/albums/72157716568856993",
			gotoUrl:
				"https://www.google.com/maps?ll=-29.377219,-50.876061&z=15&t=m&hl=pt-BR&gl=BR&mapclient=embed&q=R.+S%C3%A3o+Pedro,+532+-+Centro+Gramado+-+RS+95670-000",
			capacidade:
				"Capacidade sala Digital: 18 lugares.<br/>Capacidade sala Tutoria: 8 lugares.",
		},
		{
			title: "Igrejinha",
			location: "Rua João Corrêa, 548, Centro, Igrejinha, RS.",
			image:
				[
				{ src: 'https://www.feevale.br/Comum/midias/efc4c915-b832-4dd3-a6b4-0f574bbb39ab.jpeg', alt: 'Image 1' },
				{ src: 'https://www.feevale.br/Comum/midias/ecd62950-d55a-4511-8821-132a84db154b.jpeg', alt: 'Image 2' },
				{ src: 'https://www.feevale.br/Comum/midias/63c37d60-463b-4588-a33a-4de9f31801bc.jpeg', alt: 'Image 3' },
				{ src: 'https://www.feevale.br/Comum/midias/26afaa4a-a28d-4d2c-9061-71ff58f72d1d.jpg', alt: 'Image 4' },
				{ src: 'https://www.feevale.br/Comum/midias/b154fa3f-5565-4ed7-8a2a-c726dca0339e.jpeg', alt: 'Image 5' },
				{ src: 'https://www.feevale.br/Comum/midias/ecd4e8f9-9a48-471c-8dc7-06033b6d08d4.jpeg', alt: 'Image 6' },
				{ src: 'https://www.feevale.br/Comum/midias/b54359c5-d66e-4948-9551-51e6ca9eae54.jpeg', alt: 'Image 7' },
				{ src: 'https://www.feevale.br/Comum/midias/0f8162e4-1be1-44f9-9a0c-d51045a2587b.jpg', alt: 'Image 8' },
			  ],
			phone: "(51) 3586.8800, ramal 6935",
			galleryUrl:
				"https://www.flickr.com/photos/fotosfeevale/albums/72157708304862984",
			gotoUrl:
				"https://www.google.com/maps?ll=-29.57333,-50.790307&z=15&t=m&hl=pt-BR&gl=BR&mapclient=embed&q=R.+Jo%C3%A3o+Corr%C3%AAa,+548+-+Centro+Igrejinha+-+RS+95650-000",
			capacidade:
				"Capacidade sala Digital: 26 lugares.<br/>Capacidade sala Tutoria: 8 lugares.",
		},
		{
			title: "Montenegro",
			location: "Rua João Pessoa, 1.415, Centro, Montenegro, RS.",
			image:
				[
				{ src: 'https://www.feevale.br/Comum/midias/b1ccd942-cbf6-42bf-a49d-3982a8d21e52.jpeg', alt: 'Image 1' },
				{ src: 'https://www.feevale.br/Comum/midias/30099f42-2e2e-4fb3-9d25-ec80543d95b1.jpeg', alt: 'Image 2' },
				{ src: 'https://www.feevale.br/Comum/midias/478f51b6-8256-400b-9010-5a18e2260f41.jpg', alt: 'Image 3' },
				{ src: 'https://www.feevale.br/Comum/midias/6120ab9b-a595-4ac0-8303-4be57346dd43.jpg', alt: 'Image 4' },
				{ src: 'https://www.feevale.br/Comum/midias/b5dbde45-b0de-4cd6-a19b-97c152b1f060.jpg', alt: 'Image 5' },
				{ src: 'https://www.feevale.br/Comum/midias/214bdd92-7580-47f4-a80e-459b9c1dc38b.jpg', alt: 'Image 6' },
			  ],
			phone: "(51) 3586.8800, ramal 6937",
			galleryUrl:
				"https://www.flickr.com/photos/fotosfeevale/albums/72157716568856993",
			gotoUrl:
				"https://www.google.com/maps?ll=-29.688776,-51.460699&z=15&t=m&hl=pt-BR&gl=BR&mapclient=embed&q=R.+Jo%C3%A3o+Pessoa,+1415+-+Centro+Montenegro+-+RS+95780-000",
			capacidade:
				"Capacidade sala Digital: 20 lugares.<br/>Capacidade sala Tutoria: 8 lugares.",
		},
		{
			title: "Nova Petrópolis",
			location:
				"Av. 15 de Novembro, 2370, Centro, Nova Petrópolis, RS. Salas 1 e 2.",
			image:
				[
				{ src: 'https://www.feevale.br/Comum/midias/322ac0b1-c107-45cc-9657-24d6b5f24042.jpg', alt: 'Image 1' },
				{ src: 'https://www.feevale.br/Comum/midias/a438f6b3-d807-4d10-8f97-0d6b3eeb96f6.jpg', alt: 'Image 2' },
				{ src: 'https://www.feevale.br/Comum/midias/0667f13c-3651-4f8d-87a5-8c8f4b22c31f.jpg', alt: 'Image 3' },
				{ src: 'https://www.feevale.br/Comum/midias/71b07bd6-bb7e-416c-8c2b-5171879827d1.jpg', alt: 'Image 4' },
				{ src: 'https://www.feevale.br/Comum/midias/bee2e4ff-0596-4a5c-b785-e25d562c9cc4.jpg', alt: 'Image 5' },
				{ src: 'https://www.feevale.br/Comum/midias/ed2c094f-44c2-43df-9383-b0a3846284c9.jpg', alt: 'Image 6' },
			  ],
			phone: "(51) 3586.8800, ramal 6942",
			galleryUrl:
				"https://www.flickr.com/photos/fotosfeevale/albums/72157716568856993",
			gotoUrl:
				"https://www.google.com/maps?ll=-29.369772,-51.105017&z=15&t=m&hl=pt-BR&gl=BR&mapclient=embed&q=Avenida+15+de+Novembro,+2370+-+Centro+Nova+Petr%C3%B3polis+-+RS+95150-000",
			capacidade:
				"Capacidade sala Digital: 32 lugares.<br/>Capacidade sala Tutoria: 8 lugares.",
		},
		{
			title: "Novo Hamburgo (Câmpus II)",
			location: "ERS-239, 2755, Novo Hamburgo, RS. Prédio Rosa - sala 215.",
			galleryUrl:
				"https://www.feevale.br/institucional/infraestrutura/campus-ii",
			image:
				[
				{ src: 'https://www.feevale.br/Comum/midias/96b2031e-d2f1-4d73-86a2-1460868a19c7.jpg', alt: 'Image 1' },
				{ src: 'https://www.feevale.br/Comum/midias/bd25cdf1-8eca-43fd-bee8-f7c063c1b6b3.jpg', alt: 'Image 2' },
				{ src: 'https://www.feevale.br/Comum/midias/cc73b551-de23-432d-ac39-575a06f82a13.jpg', alt: 'Image 3' },
				{ src: 'https://www.feevale.br/Comum/midias/5b9a5a96-fc19-42ef-a637-c0121e8753b1.jpg', alt: 'Image 4' },
				{ src: 'https://www.feevale.br/Comum/midias/7fd75ec4-3394-4c21-8d17-5848c83b42c0.jpg', alt: 'Image 5' },
				{ src: 'https://www.feevale.br/Comum/midias/b10e0490-cd74-4149-8aca-74845b212128.jpg', alt: 'Image 6' },
			  ],
			phone: "(51) 3586.8800, ramal 6932",
			gotoUrl:
				"https://www.google.com/maps?ll=-29.662867,-51.118253&z=15&t=m&hl=pt-BR&gl=BR&mapclient=embed&cid=13895729503577414069",
		},
		{
			title: "Sapiranga",
			location: "Av. 20 de Setembro, 4733, São Jacó, Sapiranga, RS.",
			image:
				[
				{ src: 'https://www.feevale.br/Comum/midias/2fd6d339-0a41-4a35-a62d-cbc06d6f4706.jpg', alt: 'Image 1' },
				{ src: 'https://www.feevale.br/Comum/midias/cc678bfa-5dec-412a-b313-2259f7b732a6.jpg', alt: 'Image 2' },
				{ src: 'https://www.feevale.br/Comum/midias/e3760c29-5648-4580-9761-845348f03dd4.jpg', alt: 'Image 3' },
				{ src: 'https://www.feevale.br/Comum/midias/e324ebb3-15a7-4bcc-a2ed-d45491a51b33.jpg', alt: 'Image 4' },
				{ src: 'https://www.feevale.br/Comum/midias/346704a3-cbaa-45e5-ba89-1b5eec996210.jpg', alt: 'Image 5' },
				{ src: 'https://www.feevale.br/Comum/midias/f58ca665-f812-478b-86bd-605b1b90e083.jpg', alt: 'Image 6' },
			  ],
			phone: "(51) 3586.8800, ramal 6933",
			galleryUrl:
				"https://www.flickr.com/photos/fotosfeevale/albums/72157709426145136",
			gotoUrl:
				"https://www.google.com/maps?ll=-29.632151,-50.999069&z=15&t=m&hl=pt-BR&gl=BR&mapclient=embed&q=Av.+20+de+Setembro,+4733+-+S%C3%A3o+Jaco+Sapiranga+-+RS+93815-016",
			capacidade:
				"Capacidade sala Digital: 32 lugares.<br/>Capacidade sala Tutoria: 8 lugares.",
		},
		{
			title: "Porto Alegre - Hub One",
			location: "R. Cândido Silveira, 198 - Auxiliadora, Porto Alegre - RS.",
			image:
				[
				{ src: 'https://www.feevale.br/Comum/midias/1def4054-857e-46bc-b3b4-16d8a618c0f1.jpg', alt: 'Image 1' },
				{ src: 'https://www.feevale.br/Comum/midias/77b5242b-1ba1-4478-8536-0cb162b97711.jpg', alt: 'Image 2' },
				{ src: 'https://www.feevale.br/Comum/midias/df20a9a4-a063-418b-84d9-44ff4c46b921.jpg', alt: 'Image 3' },
				{ src: 'https://www.feevale.br/Comum/midias/327b21a5-5882-40e6-9fad-a6575c2578f2.jpg', alt: 'Image 4' },
				{ src: 'https://www.feevale.br/Comum/midias/5a3a6cc5-e6f9-43cb-9579-546b026e9fa5.jpg', alt: 'Image 5' },
				{ src: 'https://www.feevale.br/Comum/midias/503c75ee-b2ca-49e0-b6ed-2fdae5abc6d8.jpeg', alt: 'Image 6' },
				{ src: 'https://www.feevale.br/Comum/midias/7b1ca023-707f-4ec0-bacf-c9045e942c65.jpeg', alt: 'Image 7' },
				{ src: 'https://www.feevale.br/Comum/midias/37b335f6-3b8b-4cb7-9a78-50bb8630676e.jpeg', alt: 'Image 8' },
				{ src: 'https://www.feevale.br/Comum/midias/f1e0fa86-1a3f-4678-ba04-46ecf43c1cb4.jpg', alt: 'Image 9' },
			  ],
			phone: "(51) 3586.8800, ramal 6940",
			galleryUrl:
					"https://feevaletechpark.com.br/unidades-parque-tecnologico/feevale-techpark-hub-one-porto-alegre/",
			gotoUrl:
				"https://goo.gl/maps/AnAzpnwektivXSPJ7",
			capacidade:
				"Capacidade Espaço Talk: 16 lugares.<br/>Capacidade sala Digital: 8 lugares.<br/>Capacidade sala Tutoria: 2 lugares.",
		},
	];

	return (
		<>
			<SEO
				title="Polos da Universidade Feevale"
				description="Conheça os polos da Universidade Feevale e toda infraestrutura na região."
			/>
			<Layout>
				<MDBContainer>
					<MDBRow>
						<MDBCol>
							<h2 className="h1-responsive font-weight-bold text-center my-5 text-default">
								Polos
							</h2>
						</MDBCol>
					</MDBRow>
					<MDBRow>
						{conteudo.map((conteudo) => (
							<MDBCol key={conteudo.title} sm={6} className="d-flex">
								<MDBCard className="mb-3">
								<CarrosselPolos images={conteudo.image} />

									<MDBCardBody>
										<MDBCardText />
										{conteudo.title}
										<MDBCardText>
											<p>Telefone: {conteudo.phone}</p>
											<p>Localização: {conteudo.location}</p>
											<p
												dangerouslySetInnerHTML={{
													__html: conteudo.capacidade,
												}}
											></p>
											<MDBBtn
												href={conteudo.gotoUrl}
												target="_blank"
												color="default"
											>
												Ir até
											</MDBBtn>
										</MDBCardText>
									</MDBCardBody>
								</MDBCard>
							</MDBCol>
						))}
					</MDBRow>
				</MDBContainer>
			</Layout>
		</>
	);
}
